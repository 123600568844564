import React, {useEffect, useState} from 'react'
import { createRoot } from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import SceneContent from './components/hero/sceneContent'

function App() {
  const [scrollProgress, setScrollProgress] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollHeight = (document.querySelector('.hero'))?.offsetHeight
      if(scrollHeight) setScrollProgress(window.scrollY / scrollHeight)
    })
  }, []);


  return (
    <div id="hero-canvas-container">
      <Canvas linear={true}>
        <ambientLight intensity={1} />
        {/*<directionalLight color="white" position={[0, 0, 5]} />*/}
        <SceneContent scrollProgress={scrollProgress} />
      </Canvas>
      <div id="hero-progress-bar"></div>
    </div>
  )
}

const domNode = document.getElementById('hero-content')
const root = createRoot(domNode)

root.render(<App />)
