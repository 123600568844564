import Logo3D from './logo3D'
import Logo2D from './logo2D'
import React, {useState} from 'react'
import {useFrame} from '@react-three/fiber'
import move from './move'

const keyframes = {
  start: {
    logo3D: {
      position: [0.31, 0.77, 0],
      scale: [1, 1, 1],
      rotation: [0, 0, -Math.PI / 2],
      opacity: 1,
    },
    logo2D: {
      position: [0, 0, -1.6],
      scale: [0.9, 0.9, 0.9],
      rotation: [0, 0, 0],
      opacity: 0,
    },
    group: {
      position: [0, 0, -2],
      scale: [1,1,1],
      rotation: [0, 0, Math.PI/2],
    }
  },
  end: {
    logo3D: {
      position: [0.31, 0.77, 0],
      scale: [1, 1, 0.0001],
      rotation: [0, 0, -Math.PI / 2],
      opacity: 1,
    },
    logo2D: {
      position: [0, 0, -1.6],
      scale: [0.97, 0.97, 0.97],
      rotation: [0, 0, 0],
      opacity: 0,
    },
    group: {
      position: [0, 0, 0],
      scale: [1,1,1],
      rotation: [0, Math.PI*2, 0],
    }
  },
}

// function animateRef(ref, keyframe, objectName, timeDelta, inertia = 10) {
//   ref.current.position.x = move(ref.current.position.x, keyframes[keyframe][objectName].position[0], timeDelta, inertia)
//   ref.current.position.y = move(ref.current.position.y, keyframes[keyframe][objectName].position[1], timeDelta, inertia)
//   ref.current.position.z = move(ref.current.position.z, keyframes[keyframe][objectName].position[2], timeDelta, inertia)
//
//   ref.current.rotation.x = move(ref.current.rotation.x, keyframes[keyframe][objectName].rotation[0], timeDelta, inertia)
//   ref.current.rotation.y = move(ref.current.rotation.y, keyframes[keyframe][objectName].rotation[1], timeDelta, inertia)
//   ref.current.rotation.z = move(ref.current.rotation.z, keyframes[keyframe][objectName].rotation[2], timeDelta, inertia)
//
//   ref.current.scale.y = move(ref.current.scale.y, keyframes[keyframe][objectName].scale[1], timeDelta, inertia)
//   ref.current.scale.x = move(ref.current.scale.x, keyframes[keyframe][objectName].scale[0], timeDelta, inertia)
//   ref.current.scale.z = move(ref.current.scale.z, keyframes[keyframe][objectName].scale[2], timeDelta, inertia)
// }

function animateProperty(ref, objectName, property, startKeyframe, endKeyframe, progress, delta) {
  ref.current[property].x = move(ref.current[property].x, keyframes[startKeyframe][objectName][property][0] + progress * (keyframes[endKeyframe][objectName][property][0] - keyframes[startKeyframe][objectName][property][0]), delta, 10)
  ref.current[property].y = move(ref.current[property].y, keyframes[startKeyframe][objectName][property][1] + progress * (keyframes[endKeyframe][objectName][property][1] - keyframes[startKeyframe][objectName][property][1]), delta, 10)
  ref.current[property].z = move(ref.current[property].z, keyframes[startKeyframe][objectName][property][2] + progress * (keyframes[endKeyframe][objectName][property][2] - keyframes[startKeyframe][objectName][property][2]), delta, 10)
}

export default function sceneContent ({scrollProgress}) {
  const logo2DRef = React.useRef()
  const logo3DRef = React.useRef()
  const logoRef = React.useRef()
  const zoomRef = React.useRef()

  const [keyframe, setKeyframe] = useState('start')

  useFrame(({ clock, camera }, delta) => {
    const progress = Math.min(scrollProgress * 2.5, 1)
    const timeDelta = delta * 1000

    const progressBar = document.getElementById('hero-progress-bar')
    progressBar.style.width = progress * 100 + '%'
    progressBar.style.transform = 'translateX(' + Math.max(0, Math.min(scrollProgress * 2.5 - 1, 1)) * 100 + '%)'

    const zoomCorrection = (1 - Math.max(0, Math.min(1200, window.innerWidth) - 350) / 850) * 8
    zoomRef.current.position.z = -zoomCorrection

    if(progress === 0) {
      logoRef.current.position.y = move(logoRef.current.position.y, Math.pow(0.3,Math.sin(clock.getElapsedTime()) * 0.3) - 1, timeDelta, 10)
      logoRef.current.rotation.x = move(logoRef.current.rotation.x, Math.cos(clock.getElapsedTime()*0.7) * 0.1, timeDelta, 10)
      logoRef.current.rotation.y = move(logoRef.current.rotation.y, Math.sin(clock.getElapsedTime()*1.2) * 0.2, timeDelta, 10)
      logoRef.current.rotation.z = move(logoRef.current.rotation.z, keyframes['start'].group.rotation[2], timeDelta, 10)
    } else if(progress >= 1) {
      logoRef.current.position.y = move(logoRef.current.position.y, keyframes['end'].group.position[1], timeDelta, 10)
      logoRef.current.rotation.x = move(logoRef.current.rotation.x, keyframes['end'].group.rotation[0], timeDelta, 10)
      logoRef.current.rotation.y = move(logoRef.current.rotation.y, keyframes['end'].group.rotation[1], timeDelta, 10)
      logoRef.current.rotation.z = move(logoRef.current.rotation.z, keyframes['end'].group.rotation[2], timeDelta, 10)
    }

    animateProperty(logoRef, 'group', 'position', 'start', 'end', progress, timeDelta)
    animateProperty(logoRef, 'group', 'rotation', 'start', 'end', progress, timeDelta)

    animateProperty(logo2DRef, 'logo2D', 'position', 'start', 'end', progress, timeDelta)
    animateProperty(logo2DRef, 'logo2D', 'rotation', 'start', 'end', progress, timeDelta)
    animateProperty(logo2DRef, 'logo2D', 'scale', 'start', 'end', progress, timeDelta)

    animateProperty(logo3DRef, 'logo3D', 'position', 'start', 'end', progress, timeDelta)
    animateProperty(logo3DRef, 'logo3D', 'rotation', 'start', 'end', progress, timeDelta)
    animateProperty(logo3DRef, 'logo3D', 'scale', 'start', 'end', progress, timeDelta)
  })

  return (
    <group ref={zoomRef}>
        <group
          ref={logoRef}
          onClick={() => {
            setKeyframe('end')
          }}
          scale={keyframes['start'].group.scale}
          rotation={keyframes['start'].group.rotation}
          position={keyframes['start'].group.position}
        >
          <Logo3D
            path={'/wp-content/themes/whatif/assets/logo2.gltf'}
            position={keyframes['start'].logo3D.position}
            scale={keyframes['start'].logo3D.scale}
            rotation={keyframes['start'].logo3D.rotation}
            opacity={keyframes[keyframe].logo3D.opacity}
            innerRef={logo3DRef}
            scrollProgress={scrollProgress}
          />

          <Logo2D
            visible={true}
            size={[8, 8]}
            path={'/wp-content/themes/whatif/assets/hero-texture.png'}
            innerRef={logo2DRef}
            position={keyframes['start'].logo2D.position}
            scale={keyframes['start'].logo2D.scale}
            rotation={keyframes['start'].logo2D.rotation}
            opacity={keyframes[keyframe].logo2D.opacity}
            scrollProgress={scrollProgress}
          />
        </group>
    </group>
  )
}