import {useFrame, useLoader} from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three';
import {useEffect} from "react";
import move from "./move";

export default function logo3D ({path, position, scale, rotation, opacity, innerRef, scrollProgress}) {
  const gltf = useLoader(GLTFLoader, path)
  const scene = gltf.scene.clone(true)

  const traverse = (child, callback) => {
    callback(child)
    if(Array.isArray(child.children) && child.children.length > 0) {
      child.children.forEach((subchild) => {
        traverse(subchild, callback)
      })
    }
  }
  scene.children.forEach((child) => {
    traverse(child, function (item) {
      if(item.isMesh) {
        // item.castShadow = true
        // item.receiveShadow = true
        item.material.emissive = new THREE.Color('#EF4129')
        item.material.emissiveIntensity = 1
        item.material.transparent = true
      }
    })
  })

  function setOpacity(value, delta = false) {
    scene.children.forEach((child) => {
      traverse(child, function (item) {
        if(item.isMesh) {
          item.material.opacity = delta === false ? value : move(item.material.opacity, value, delta*1000, 5)
        }
      })
    })
  }

  useFrame(({ clock }, delta) => {
    const progress = Math.min(scrollProgress * 2.5, 1)
    setOpacity(progress >= 0.95 ? 0 : 1, delta)
  })


  return (
    <group ref={innerRef} position={position} scale={scale} rotation={rotation} renderOrder={1}>
      <primitive
        object={scene}
        scale={0.0076}
        position={[0, 0, -1.8]}
      />
    </group>
  )
}