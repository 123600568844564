import {useFrame, useLoader} from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import {useEffect, useRef} from "react";
import move from "./move";

export default function logo2D ({path, size, visible = true, opacity, innerRef, position, rotation, scale, scrollProgress}) {
  const colorMap = useLoader(TextureLoader, path)
  const materialRef = useRef()

  useFrame(({ clock }, delta) => {
    const progress = Math.min(scrollProgress * 2.5, 1)
    const target = progress < 0.95 ? 0 : 1
    materialRef.current.opacity = move(materialRef.current.opacity, target, delta*1000, 10)
  })

  useEffect(() => {
    materialRef.current.opacity = 0
  }, []);


  return (
    <group position={[0, 0, 1.65]}>
      <mesh ref={innerRef} position={position} rotation={rotation} scale={scale} renderOrder={2}>
        <planeGeometry args={size} />
        <meshBasicMaterial ref={materialRef} visible={visible} map={colorMap} transparent={true}/>
      </mesh>
    </group>
  )
}