export default function (source, target, timedelta, inertia) {
  if (timedelta > 1000) timedelta = 1000;
  if (inertia === null || inertia === undefined) {
    inertia = 20
  }
  let dir = 1;
  if (source > target) dir = -1;
  let move = -dir * timedelta / (inertia * 16.66) * Math.abs(source - target);
  source = source - move;
  if (dir === 1 && source > target) source = target;
  if (dir === -1 && source < target) source = target;
  return Number(source).toFixed(5);
}